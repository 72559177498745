import React from 'react';
import type { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import Toaster from '@engbers/components/online-shops/commercetools-ui/toaster';
import { FrontasticProvider } from '@frontastic-engbers/lib';
import 'tailwindcss/tailwind.css';
import { URLHistoryProvider } from '@frontastic-engbers/lib';
import '../styles/app.scss';
import '../styles/components/slider.css';
import '../styles/components/default-loader.css';
function FrontasticStarter({
  Component,
  pageProps
}: AppProps) {
  return <FrontasticProvider context="shop" data-sentry-element="FrontasticProvider" data-sentry-component="FrontasticStarter" data-sentry-source-file="_app.tsx">
      <URLHistoryProvider data-sentry-element="URLHistoryProvider" data-sentry-source-file="_app.tsx">
        <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
        <Toaster data-sentry-element="Toaster" data-sentry-source-file="_app.tsx" />
      </URLHistoryProvider>
    </FrontasticProvider>;
}
export default appWithTranslation(FrontasticStarter);